import { useState } from 'react'
import Avatar from 'shared/components/header/components/avatar'
import { DesktopMenu } from 'shared/components/header/components/desktop-menu'
import SideMenu from 'shared/components/header/components/side-menu'
import SioButton from 'shared/components/header/components/sio-button'
import WorkspaceSelector from 'shared/components/header/components/workspace-selector'
import { DesktopLanguageSwitcher } from 'shared/components/language-switcher/desktop-language-switcher'

function Header() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <nav className="top-0 bg-darkblue z-10">
      <div className="flex w-full mx-auto items-center justify-between h-[69px] text-white z-10 px-4 sm:px-6 md:px-14 gap-4">
        <div className="flex items-center gap-4">
          <SioButton />
          <div className={'hidden lg:flex'}>
            <WorkspaceSelector />
          </div>
        </div>
        <SideMenu isOpen={isOpen} setIsOpen={setIsOpen} />
        <DesktopMenu />
        <div className={`hidden h-full lg:flex items-center justify-around gap-4 shrink-0`}>
          <Avatar />
          <DesktopLanguageSwitcher />
        </div>
      </div>
    </nav>
  )
}

export default Header
