const LocalizeNamespaces = {
  //common
  confirmation: 'confirmation',
  actions: 'actions',
  currency: 'currency',
  datepicker: 'datepicker',
  global: 'global',
  locale: 'locale',
  list: 'list',
  name_of_times: 'name_of_times',

  //dashboard
  affiliate: 'affiliate',
  affiliate_dashboard: 'affiliate_dashboard',
  affiliate_program: 'affiliate_program',
  automation_rule: 'automation_rule',
  blog: 'blog',
  campaign_step_activating: 'campaign_step_activating',
  campaign_step_editor: 'campaign_step_editor',
  campaign_step: 'campaign_step',
  campaign: 'campaign',
  communities: 'communities',
  contact_email_settings: 'contact_email_settings',
  contact_import: 'contact_import',
  contact_page: 'contact_page',
  contact_bin: 'contact_bin',
  contacts: 'contacts',
  coupon: 'coupon',
  course_quiz: 'course_quiz',
  course_bundles: 'course_bundles',
  course_email: 'course_email',
  course: 'course',
  digital_product: 'digital_product',
  disabled: 'disabled',
  email_statistics: 'email_statistics',
  email_template_selector: 'email_template_selector',
  email_delivery_statistics: 'email_delivery_statistics',
  email_pending_mailing: 'email_pending_mailing',
  email: 'email',
  export_csv: 'export_csv',
  file_upload: 'file_upload',
  funnel_templates_management: 'funnel_templates_management',
  blog_templates_management: 'blog_templates_management',
  page_templates_management: 'page_templates_management',
  email_templates_management: 'email_templates_management',
  funnel: 'funnel',
  header: 'header',
  invoice_state: 'invoice_state',
  invoice: 'invoice',
  mailing: 'mailing',
  main: 'main',
  newsletter_editor: 'newsletter_editor',
  newsletter_split_test: 'newsletter_split_test',
  newsletter: 'newsletter',
  notifications: 'notifications',
  payment_type: 'payment_type',
  payment_status: 'payment_status',
  payment_methods: 'payment_methods',
  payment: 'payment',
  products: 'products',
  students: 'students',
  subscriptions: 'subscriptions',
  tag: 'tag',
  transactions: 'transactions',
  user: 'user',
  user_list: 'user_list',
  user_role: 'user_role',
  validation: 'validation',
  workflow: 'workflow',
  user_plans: 'user_plans',
  suspicious_email_address: 'suspicious_email_address',
  orders: 'orders',
  statistics: 'statistics',
  settings_menu: 'settings_menu',
  notification_settings: 'notification_settings',
  workspace_members_settings: 'workspace_members_settings',
  change_password_settings: 'change_password_settings',
  affiliate_commissions_settings: 'affiliate_commissions_settings',
  active_campaign_settings: 'active_campaign_settings',
  public_api_settings: 'public_api_settings',
  custom_domain_settings: 'custom_domain_settings',
  affiliate_program_settings: 'affiliate_program_settings',
  sales_funnel_settings: 'sales_funnel_settings',
  payment_settings: 'payment_settings',
  course_settings: 'course_settings',
  mailing_settings: 'mailing_settings',
  subscriptions_settings: 'subscriptions_settings',
  payment_gateways_settings: 'payment_gateways_settings',
  user_plan_settings: 'user_plan_settings',
  account_settings: 'account_settings',
  code_editor: 'code_editor',
  share: 'share',
  store: 'store',
  suspicious_email_keyword: 'suspicious_email_keyword',
  stores: 'stores',
  suspicious_page_content_keyword: 'suspicious_page_content_keyword',
  blocked_mailbox_provider_management: 'blocked_mailbox_provider_management',
  white_list_mailbox_management: 'white_list_mailbox_management',
  webhooks_settings: 'webhooks_settings',
  user_customer_management: 'user_customer_management',
  shipping_settings: 'shipping_settings',
  google_integration: 'google_integration',
  zoom_integration: 'zoom_integration',
  crm_pipeline: 'crm_pipeline',
  booking_calendar: 'booking_calendar',
  file_manager: 'file_manager',
  integrations_setting: 'integrations_setting',
  simple_mail_editor: 'simple_mail_editor'
}

Object.freeze(LocalizeNamespaces)

export default LocalizeNamespaces
