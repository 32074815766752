import axios, { AxiosRequestConfig } from 'axios'
import { regenerateJWT } from 'shared/api/regenerate-jwt'
import { ConflictError } from 'shared/errors/conflict-error'
import { invalidTokenErrorMessages } from 'shared/errors/invalid-token-error'
import { NetworkError } from 'shared/errors/network-error'
import { PayloadTooLarge } from 'shared/errors/payload-too-large'
import { UnprocessableContentError } from 'shared/errors/unprocessable-content-error'
import { AccessDenied } from '../errors/access-denied'
import { BadRequest } from '../errors/bad-request'
import { InternalError } from '../errors/internal-error'
import { NotFound } from '../errors/not-found'
import { UnauthorizedResponse } from '../errors/unauthorized-response'

let isRefreshing = false
let refreshSubscribers: (() => void)[] = []

const subscribeTokenRefresh = (callback: () => void) => {
  refreshSubscribers.push(callback)
}

const onRefreshed = () => {
  refreshSubscribers.forEach(callback => callback())
  refreshSubscribers = []
}

const httpClient = axios.create({
  withCredentials: true,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
})

const tryToRegenerateTokenAndRepeatFailedRequests = async (error: any) => {
  const originalRequest = error.config

  if (!isRefreshing) {
    isRefreshing = true

    try {
      await regenerateJWT()
      isRefreshing = false

      onRefreshed()

      return await httpClient(originalRequest)
    } catch (err) {
      isRefreshing = false
      refreshSubscribers = []
      throw err
    }
  }

  return new Promise(resolve => {
    subscribeTokenRefresh(() => {
      resolve(httpClient(originalRequest))
    })
  })
}

httpClient.interceptors.response.use(
  response => {
    if (response.status === 202) {
      window.Rollbar.info('Unexpected 202 response', response)
    }
    return response
  },
  async error => {
    if (axios.isCancel(error)) {
      throw error
    }

    if (error.response) {
      if (error.response.status === 400) {
        throw new BadRequest(error.response.data)
      } else if (error.response.status === 409) {
        throw new ConflictError(error.response.data)
      } else if (error.response.status === 422) {
        throw new UnprocessableContentError(error.response.data)
      } else if (error.response.status === 401) {
        if (invalidTokenErrorMessages.includes(error.response.data.message)) {
          return await tryToRegenerateTokenAndRepeatFailedRequests(error)
        } else if (error.response.data.location) {
          throw new UnauthorizedResponse(error.response.data.location)
        } else {
          ;(window as any).Rollbar.error('Unauthorized response', error.response.data)
        }
      } else if (error.response.status === 403) {
        throw new AccessDenied()
      } else if (error.response.status === 404) {
        throw new NotFound()
      } else if (error.response.status === 413) {
        throw new PayloadTooLarge()
      } else if (error.response.status === 500 || error.response.status === 502) {
        throw new InternalError()
      } else if (error.code === 'ERR_NETWORK') {
        throw new NetworkError()
      }
    }
  },
)

export const baseFetcher = (url: string, config?: AxiosRequestConfig) =>
  httpClient
    .get(url, config)
    .then(res => res.data)
    .catch(e => {
      throw e
    })

export default httpClient
